$gauges: (
  'hunger': red,
  'mood': chartreuse,
  'energy': orange,
  'peepoo': blue,
  'libido': magenta,
  'health': purple,
  'suspicion': cyan,
);

$skills: (
  'feelings': purple,
  'needs': blue,
  'smell': green,
  'sound': orange,
  'vision': turquoise,
);

#app > .main-scene {
    padding: 2em 5em;
    width: auto;
    height: calc(100vh - 4em);
    overflow: auto;
    header {
        display: flex;
        align-items: center;
        & > main {
            flex: 1;
            display: flex;
            align-items: center;
            margin: 0px 2em;
            width: 100%;
            & > div {
                display: flex;
            }
        }

        & > p {
            color: white;
            font-weight: bold;
            font-size: 1.5em;
        }
    }

    & > main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .outcome {
            padding: 5em auto;
            color: white;
            p {
                font-size: 2em;
                margin: 3em auto 2em;
                text-align: center;
            }
        }

        .illus {
            display: grid;
            justify-content: center;
            justify-items: center;
            align-items: center;
        }
        img {
            grid-row: 1 / 1;
            grid-column: 1 / 1;
            display: block;
            height: max(15vw, 200px);
            margin: 3em 1em;
        }

        & > section {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;

            .card {
                margin: 1em;
                animation-name: float;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                display: flex;
                flex-direction: row;
                padding: 0px;
                align-items: center;
                overflow: hidden;

                .icon {
                    display: inline;
                    padding: 16px;
                    margin: 0px;
                    max-width: 32px;
                    height: calc(100% - 32px);

                    @each $name, $color in $skills {
                        &.#{$name} {
                            background: $color;
                        }
                    }
                }

                span {
                    margin: 1em;
                }
            }

            .button {
                flex: 1;
                margin: 1em;
            }
        }

        .description {
            color: white;
            text-align: center;
            margin: 1.5em auto;
        }
    }
}

.gauge {
    flex: 1;
    height: 16px;
    img {
        width: 32px;
        height: 32px;
        display: block;
        position: relative;
        top: -16px;
        border-radius: 32px;
        border: 2px solid white;
        padding: 4px;
        left: 16px;
        background: $darkpurple;
    }

    main {
        border-radius: 8px;
        flex: 1;
        overflow: hidden;
        div {
            height: 16px;
            border-radius: 8px 0px 0px 8px;
            transition: width 0.2s ease-in-out;
        }
    }
}

@each $name, $color in $gauges {
    .gauge.#{$name} {
        main {
            background: mix($color, white, $weight: 25%);
            div {
                background: $color;
            }    
        }
    }
}

@keyframes float {
    0% {
        transform: translate(0px, 0px);
    }
    50% {
        transform: translate(20px, 15px);
    }
    75% {
        transform: translate(15px, -5px);
    }
    100% {
        transform: translate(-20px, -10px);
    }
}

@media screen and (max-width: 560px) {
    #app > .main-scene {
        padding: 1em;
        overflow-y: auto;
        header {
            flex-direction: column;
            main {
                flex-direction: column;
            }
        }

        & > main {
            .hints {
                align-items: center;
            }
            & > section {
                flex-direction: column;
            }
        }
    }

    .gauge {
        flex: 0;
        width: 100%;
        margin-bottom: 0.5em;
        main {
            height: 16px;
        }
    }
}
