$margin: 3em;

.modal-skills-tree {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 100;

    pointer-events: none;

    transition: background 0.3s;

    &[data-visible="true"] {
        background: rgba(black, 0.7);
        pointer-events: all;
    }

    & > .dialog {
        display: block;

        margin: $margin;
        width: calc(100% - 2 * 3em);
        height: calc(100% - 2 * 3em);

        border-radius: 2em;

        background: $darkpurple;
        opacity: 0.0;

        transition: opacity 0.3s;

        & > :first-child {
            width: 100%;
            height: 100%;
        }
    }

    &[data-visible="true"] > .dialog {
        opacity: 1.0;
    }
}
