$darkpurple: #16142f;
$purple: #252444;
$lightpurple: #3f4d6c;
$blue: #4ac4be;
$cyan: #49ffea;

* {
    margin: 0px;
    padding: 0px;
    font-family: sans-serif;
}

html, body, #app, #app > * {
    background: $darkpurple;
    width: 100%;
    height: 100%;
    overflow: hidden; // for tentacles
}

.invisible {
    display: none;
}

// Components
@import '_buttons';
@import '_card';
@import 'game_over';
@import 'intro';
@import 'modal_skills_tree';
@import 'skills_tree';
@import '_tentacles';

// Scenes
@import '_menu';
@import '_credits';
@import '_selection';
@import '_main';
