#app > .credits {
    background: $darkpurple;
    color: white;
    line-height: 1.33em;
    padding: 5em;
    height: calc(100vh - 10em);
    overflow-y: auto;
    width: auto;

    & > * {
        margin: 1em auto;
        max-width: 40vw;
    }

    .button {
        max-width: 20vw;
        margin: 2em auto;
    }

    a, a:visited {
        color: white;
    }

    ul {
        padding-left: 2em;
    }

    img {
        position: relative;
        top: 0.5em;
    }
}

@media screen and (max-width: 560px) {
    #app > .credits {
        padding: 1em;
        & > * {
            max-width: 90vw;
        }

        .button {
            max-width: 90vw;
        }
    }
}
