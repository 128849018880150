#app > .host-selection {
    background: $darkpurple;
    padding: 5em;
    height: calc(100vh - 10em);
    width: auto;
    color: white;
    overflow-y: auto;
    & > p {
        text-align: center;
    }
    main {
        display: flex;
        justify-content: space-between;
        overflow-y: auto;
        .card {
            flex: 1;
            margin: 10vh 2em 0em;
            padding-bottom: 0px;

            .button {
                margin: 1em 0em;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    #app > .host-selection {
        padding: 1em;
        main {
            margin-top: 2em;
            overflow-y: auto;
            flex-wrap: wrap;
            max-height: 90vh;
            flex-direction: row;

            .card {
                margin: 1em 0em 0em;
            }
        }
    }
}
