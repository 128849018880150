#app > .game-over {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    color: white;

    & > * {
        margin-bottom: 1em;
    }

    & > img:first-child {
        padding-top: 2em;
        width: 80%;
        max-width: 500px;
    }

    & > .gauges {
        display: flex;
        flex-direction: column;
        
        width: 100%;

        .gauge {
            display: flex;
            main {
                height: 16px;
            }
        }

        & > .gauge:not(:last-child) {
            margin-bottom: 2em;
        }
    }
}

@media screen and (min-width: 560px) {
    .game-over > * {
        max-width: 600px;
    }
}
